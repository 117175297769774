<template>
  <p
    :id="`${id || _uid}-helper-text`"
    :class="{
      'mdc-text-field-helper-text': type === 'text',
      'mdc-select-helper-text': type === 'select',
    }"
    aria-hidden="true"
    v-html="text"
  />
</template>

<script>
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import { MDCSelectHelperText } from '@material/select/helper-text';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
  },

  data: () => ({
    mdcHelperText: null,
  }),

  mounted() {
    this.mdcHelperText =
      this.type === 'text'
        ? new MDCTextFieldHelperText(this.$el)
        : new MDCSelectHelperText(this.$el);
  },

  beforeDestroy() {
    this.mdcHelperText.destroy();
  },
};
</script>
